































































































































































































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { CustomerService } from '@/services/customer/CustomerService';
import { ActionResult } from '@/models/ActionResult';
import { CustomerDetailResult } from '@/models/customer/CustomerDetailResult';
import CustomerAddressList from '@/views/customer/address/CustomerAddressList.vue';
import CustomerContactList from '@/views/customer/contact/CustomerContactList.vue';
import CustomerSocialList from '@/views/customer/social/CustomerSocialList.vue';
import CustomerBankList from '@/views/customer/bank/CustomerBankList.vue';
import * as _ from 'lodash';
import vSelect from 'vue-select';
import { BankAccount } from "@/models/bank/BankAccount";
import UserShareList from "@/components/user-share/UserShareList.vue";
import { Share } from "@/models/share/Share";
import { UserShareModel } from "@/components/user-share/UserShareModel";
import TextEditable from "@/components/text-editable/TextEditable.vue";
import TextSelectBoxEditable from "@/components/text-editable/TextSelectEditable.vue";
import TextSelectEditable from "@/components/text-editable/TextSelectEditable.vue";
import TextDateTimeEditable from "@/components/text-editable/TextDateTimeEditable.vue";
import TextTreeSelectEditable from "@/components/text-editable/TextTreeSelectEditable.vue";
import { CustomerGroupService } from "@/views/customer-group/CustomerGroupService";
import { TreeModel } from "@/components/tree/TreeModel";
import CategorySelect from "@/components/category/CategorySelect.vue";
import CustomerSuggestion from "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import { Getter, namespace } from "vuex-class";
import { CustomerConfig } from "@/models/customer/CustomerConfig";
import { CustomerStatus, CustomerType } from "@/constants/Customer";
import { Category } from "@/models/category/category";
import UploadFileButton from '@/components/upload-file/UploadFileButton.vue';
import { FileResult } from "@/components/upload-file/FileResult";
import CustomImage from '@/components/image/CustomImage.vue';
import { CategoryType } from "@/models/category/CategoryType";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";
import ActivityQuickInsert from "@/views/customer/activity/ActivityQuickInsert.vue";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import moment from "moment";

const customerStoreModule = namespace('customer');
@Component({
    components: {
        UserShareList,
        CustomerBankList,
        CustomerAddressList,
        CustomerContactList,
        CustomerSocialList,
        TextEditable,
        TextSelectBoxEditable,
        TextDateTimeEditable,
        TextTreeSelectEditable,
        CategorySelect,
        TextSelectEditable,
        CustomerSuggestion,
        CustomerGroupTreeDropdown,
        UploadFileButton,
        CustomImage,
        vSelect,
        UserSuggestion,
        VuePerfectScrollbar,
        CustomerNoteForm: () => import(/* webpackChunkName: "CustomerNoteForm" */ '@/views/customer/note/CustomerNoteForm.vue'),
        EmailCompose: () => import(/*webpackChunkName: "EmailCompose"*/ '@/views/email/EmailCompose.vue'),
        ActivityQuickInsert: () => import('@/views/customer/activity/ActivityQuickInsert.vue')
    }
})
export default class CustomerDetail extends Vue {
    @Ref('activityModal') activityQuickInsertComponent!: ActivityQuickInsert;
    @Ref('emailComponent') emailComponent!: any;
    @customerStoreModule.Action('fetchConfig') fetchConfig!: any;
    @customerStoreModule.Getter('config') config!: CustomerConfig;
    @Getter("oidcUser") currentUser!: any;
    id: string = '';
    categoryType = CategoryType;
    customerDetail: CustomerDetailResult = new CustomerDetailResult();
    listCategory: any = [];

    customerInfoContainerStyle = {
        height: '0px'
    };

    rightColStyle = {
        height: '0'
    };
    selectedIndex = 1;
    listComponent: any = [];
    selectedComponent: any = null;
    customerType = CustomerType;
    listBank: BankAccount[] = [];
    customerGroups: any[] = [];
    listStatus: any = [];

    private customerService: CustomerService;
    isLoading: boolean = false;

    get isShowApprove() {
        return this.customerDetail.isApproved == null
            && (this.config.isSalesAdmin || (this.config.isSalesChannelLeader && this.customerDetail.salesChannelId === this.config.defaultSalesChannelId));
    }

    get customerNoteFormComponent(): any {
        return this.$refs.customerNoteForm;
    }

    get customerShare() {
        if (this.customerDetail.share) {
            return this.customerDetail.share.map((customerShare: Share) => {
                return new UserShareModel(customerShare.id, customerShare.fullName, customerShare.userName, customerShare.permission, customerShare.avatar);
            })
        } else {
            return [];
        }
    }

    get foundingDateLabel() {
        return this.customerDetail.type === CustomerType.personal
            ? this.$t('Birthday') : this.$t('FoundingDate');
    }

    get isEdit() {
        return this.customerDetail.isEdit;
    }

    get statusText() {
        const status = this.listStatus.find((status: any) => {
            return status.id === this.customerDetail.status;
        });
        return status.text;
    }

    get customerManager() {
        return this.customerDetail.managerId ? [{
            id: this.customerDetail.managerId,
            text: this.customerDetail.managerFullName
        }] : null;
    }

    constructor() {
        super();
        this.customerService = new CustomerService();
        this.listComponent = [
            {
                id: 'CC_customerInsight',
                name: 'Insight',
                component: 'CC_CustomerInsight',
                url: 'insight'
            },
            {
                id: 'CC_customerActivityList',
                name: 'Hoạt động',
                component: 'CC_CustomerActivityList',
                url: 'activity'
            },
            // {
            //     id: 'customerEmailList',
            //     name: 'Email',
            //     component: 'CustomerEmailList',
            //     url: 'email'
            // },
            {
                id: 'CC_customerCallList',
                name: 'Calls',
                component: 'CC_CustomerCallList',
                url: 'call'
            },
            // {
            //     id: 'customerProjectList',
            //     name: 'Dự án',
            //     component: 'CustomerProjectList',
            //     url: 'project'
            // },
            {
                id: 'CC_customerOrderList',
                name: 'Đơn hàng',
                component: 'CC_CustomerOrderList',
                url: 'order'
            },
            {
                id: 'CC_debtList',
                name: 'Công nợ',
                component: 'CC_Debt',
                url: 'debt'
            },
            // {
            //     id: 'customerGuaranteeList',
            //     name: 'Bảo hành',
            //     component: 'CustomerGuaranteeList',
            //     url: 'guarantee'
            // },
            // {
            //     id: 'customerNoteList',
            //     name: 'Ghi chú',
            //     component: 'CustomerNoteList',
            //     url: 'note'
            // },
            // {
            //     id: 'customerTaskList',
            //     name: 'Công việc',
            //     component: 'CustomerTaskList.vue',
            //     url: 'task'
            // },
            // {
            //     id: 'customerQuotationList',
            //     name: 'Chính sách giá',
            //     component: 'customerQuotationList.vue',
            //     url: 'quotation'
            // }
        ];
    }

    // beforeMount() {
    //     this.getListCategory();
    // }

    created() {
        this.id = this.$route.params.id;

        if (this.id) {
            this.customerService.getDetail(this.id)
                .then((result: ActionResult<CustomerDetailResult>) => {
                    if (result.code > 0) {
                        this.customerDetail = result.data as CustomerDetailResult;
                        this.customerDetail.foundingDate = this.customerDetail.foundingDate
                            ? moment(this.customerDetail.foundingDate, 'MM/DD/YYYY hh:mm:ss').format('DD-MM-YYYY')
                            : '';
                        this.listBank = this.customerDetail.banks;
                    }
                }).catch((error: any) => {
            });
        }

        this.listStatus = [
            {id: CustomerStatus.Official, text: 'Chính thức'},
            {id: CustomerStatus.Potential, text: 'Tiềm năng'},
            {id: CustomerStatus.InStore, text: 'Đang ở kho'},
        ];
    }

    async mounted() {
        await this.fetchConfig();

        // this.$store.dispatch('pageTitle', 'Chi tiết khách hàng');
        const routerName = this.$route.name;
        setTimeout(() => {

            if (routerName === 'CC_customerActivityList') {

                const selectedTab = this.listComponent[this.selectedIndex];
                if (selectedTab) {
                    if (this.$route.name === selectedTab.id) {
                        return;
                    }

                    this.$router.push({
                        name: selectedTab.id
                    });
                }
            } else {
                const index = _.findIndex(this.listComponent, (component: any) => {
                    return component.id === routerName;
                });
                if (index > -1) {
                    this.selectedIndex = index;
                }
            }
        }, 200);

        // get customer group.
        this.getCustomerGroup();

        setTimeout(() => {
            this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
        })
    }

    get customerImage() {
        if (this.customerDetail.image) {
            return `${process.env.VUE_APP_GATEWAY_URL}files/${this.customerDetail.image}`;
        } else {
            return '';
        }
    }

    get isCompany() {
        return this.customerDetail.type === CustomerType.company;
    }

    onManagerSelected(suggestions: SuggestionModel[]) {
        const suggestion = suggestions[0];
        if (suggestion) {
            this.customerDetail.managerId = suggestion.id;
            this.customerDetail.managerFullName = suggestion.text;

            const oldManagerId = _.cloneDeep(this.customerDetail.managerId);
            const oldManagerFullName = _.cloneDeep(this.customerDetail.managerFullName);
            this.updateManager(oldManagerId, oldManagerFullName);
        }
    }

    onRemoveManager() {
        const oldManagerId = _.cloneDeep(this.customerDetail.managerId);
        const oldManagerFullName = _.cloneDeep(this.customerDetail.managerFullName);

        this.customerDetail.managerId = '';
        this.customerDetail.managerFullName = '';
        this.updateManager(oldManagerId, oldManagerFullName);
    }

    backToList() {
        this.$router.push('/customer');
    }

    onImageChanged(data: FileResult[]) {
        const image = data[0];
        this.customerDetail.image = `${image.id}`;
        this.onCustomerUpdated();
    }

    onAcceptChangeCustomerGroup(event: any) {
        this.customerDetail.salesChannelName = event.label;
        this.onCustomerUpdated();
    }

    onTagClicked(component: any, index: number) {
        this.selectedIndex = index;
        if (component) {
            this.$router.push({
                name: component.id
            });
        }
    }

    onBankAdded(bankAccount: BankAccount) {
        this.customerService.addBank(this.id, bankAccount).then((result: ActionResult) => {
            if (result.code > 0) {
                this.listBank = [...this.listBank, bankAccount];
            }
            this.$vs.notify({
                title: '',
                text: result.message,
                color: 'success'
            });
        }, error => {
            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        });
    }

    onBankUpdated(bankAccount: BankAccount) {
        const bankAccountInfo = _.find(this.listBank, (item: BankAccount) => {
            return item.id == bankAccount.id;
        });

        if (bankAccountInfo) {
            bankAccountInfo.bankId = bankAccount.bankId;
            bankAccountInfo.bankName = bankAccount.bankName;
            bankAccountInfo.branchId = bankAccount.branchId;
            bankAccountInfo.branchName = bankAccount.branchName;
            bankAccountInfo.beneficiary = bankAccount.beneficiary;
            bankAccountInfo.concurrencyStamp = bankAccount.concurrencyStamp;
        }
    }

    onBankRemove(bankAccountId: string) {
        this.customerService.removeBank(this.id, bankAccountId)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    const index = _.findIndex(this.listBank, (bankAccount: BankAccount) => {
                        return bankAccount.id === bankAccountId;
                    });

                    if (index > -1) {
                        this.$delete(this.listBank, index);
                    }
                }
                this.$vs.notify({
                    title: '',
                    text: result.message,
                    color: result.code <= 0 ? 'danger' : 'success'
                });
            });
    }

    onSelectCustomerResource(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.resourceId = category.id as string;
            this.customerDetail.resourceName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectClassify(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.classifyId = category.id as string;
            this.customerDetail.classifyName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectPotential(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.potentialLevelId = category.id as string;
            this.customerDetail.potentialLevelName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectClarification(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.clarificationId = category.id as string;
            this.customerDetail.clarificationName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectStrategy(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.strategyId = category.id as string;
            this.customerDetail.strategyName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectFocusGroup(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.focusGroupId = category.id as string;
            this.customerDetail.focusGroupName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectBusinessCareer(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.businessCareerId = category.id as string;
            this.customerDetail.businessCareerName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectStandardLiving(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.standardLivingId = category.id as string;
            this.customerDetail.standardLivingName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectCompanySize(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.companySizeId = category.id as string;
            this.customerDetail.companySizeName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    onSelectRevenue(categories: Category[]) {
        const category = categories[0];
        if (category) {
            this.customerDetail.revenueId = category.id as string;
            this.customerDetail.revenueName = category.name as string;
            this.onCustomerUpdated();
        }
    }

    async onCustomerUpdated() {
        try {
            this.customerService.update(this.id, this.customerDetail)
                .then((result: ActionResult<string>) => {
                    if (result.code > 0) {
                        if (result.data) {
                            this.customerDetail.concurrencyStamp = result.data;
                        }
                    }

                    if (result.code <= 0) {
                        this.$vs.notify({
                            title: '',
                            text: result.message,
                            color: result.code > 0 ? 'success' : 'danger'
                        });
                    }
                });
        } catch (message) {
            this.$vs.notify({
                title: '',
                text: message,
                color: 'danger'
            });
        }
    }

    call() {
        window.location.href = `tel:${this.customerDetail.phoneNumber}`;
    }

    userShareAdded(userShare: UserShareModel[]) {
        this.customerService.updateShare(this.customerDetail.id, userShare)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    this.customerDetail.share = userShare.map((item: UserShareModel) => {
                        return new Share(item.id, item.fullName, item.userName, item.permission, item.avatar);
                    });
                }
            });
    }

    userSharePermissionChanged(data: any) {
        this.customerService.updateSharePermission(this.customerDetail.id, data.id, data.permission)
            .then((result: ActionResult) => {
                this.$vs.notify({
                    title: '',
                    text: result.message,
                    color: result.code <= 0 ? 'danger' : 'success'
                });
                if (result.code > 0) {
                    const customerShare = this.customerDetail.share.find((customerShare: Share) => {
                        return customerShare.id === data.id;
                    });
                    if (customerShare) {
                        customerShare.permission = data.permission;
                    }
                }
            })
    }

    userShareDeleted(id: string) {
        this.customerService.deleteShare(this.customerDetail.id, id)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    const index = this.customerDetail.share.findIndex((customerShare: Share) => {
                        return customerShare.id === id;
                    });
                    if (index != -1) {
                        this.$delete(this.customerDetail.share, index);
                    }
                }
            }).catch((error) => {
            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        });
    }

    addNote() {
        this.customerNoteFormComponent.add();
    }

    selectComponent(component: any) {
        this.selectedComponent = component.component;
    }

    confirmApprove() {
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('ConfirmApproveTitle'),
            text: this.$t('ConfirmDeclineText', [this.customerDetail.name]),
            accept: this.changeApproveStatus,
            acceptText: this.$t("Delete")
        });
    }

    sendEmail() {
        this.emailComponent.show();
    }

    addActivity() {
        this.activityQuickInsertComponent.show();
    }

    saveSuccessActivity() {
        setTimeout(() => {
            this.$router.push(`/customer/detail/${this.customerDetail.id}/activity`);
        }, 1000)
    }

    async changeApproveStatus(isApprove: boolean = false) {
        try {
            const result = await this.customerService.approve(this.customerDetail.id, isApprove);
            this.customerDetail.isApproved = isApprove;
            this.$vs.notify({
                title: '',
                text: result.message,
                color: 'success'
            });
        } catch (e) {
            this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            });
        }
    }

    async deleteCustomer() {
        const result = await this.customerService.deleteCustomer(this.customerDetail.id);
    }

    // private async getListCategory() {
    //     this.isLoading = true;
    //     const result = await new CategoryService().getByTypes([CategoryType.CustomerResources, CategoryType.CustomerClassify, CategoryType.CustomerPotentialLevel,
    //         CategoryType.CustomerClarification, CategoryType.CustomerSalesStrategy, CategoryType.CustomerFocusGroup, CategoryType.CustomerBusinessCareer, CategoryType.PersonalCustomerBusinessCareer,
    //         CategoryType.CustomerCompanySize, CategoryType.Revenue, CategoryType.CustomerStandardLiving]);
    //     this.isLoading = false;
    //     if (result.code > 0) {
    //         this.listCategory = result.items;
    //     } else {
    //         this.$vs.notify({
    //             title: '',
    //             text: result.message,
    //             color: 'danger'
    //         });
    //     }
    // }

    private buildLayout() {
        const headBlock = this.$refs.headBlock as HTMLDivElement;
        const customerInfoContainer = this.$refs
            .customerInfoContainer as HTMLDivElement;
        const windowHeight = window.innerHeight;
        const headerHeight = 100;
        const headBlockHeight = headBlock.clientHeight;

        const customerInfoContainerHeight =
            windowHeight - (headerHeight + headBlockHeight);
        const rightColHeight = windowHeight - headerHeight;
        this.customerInfoContainerStyle.height = `${customerInfoContainerHeight}px`;
        this.rightColStyle.height = `${rightColHeight}px`;
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerGroups = result;
            });
    }

    private updateManager(oldManagerId: string, oldManagerFullName: string, callback?: Function) {
        this.customerService.updateManager(this.customerDetail.id, this.customerDetail.managerId, this.customerDetail.managerFullName, this.customerDetail.concurrencyStamp)
            .then((result: ActionResult<string>) => {
                if (result.code <= 0) {
                    this.customerDetail.managerId = _.cloneDeep(oldManagerId);
                    this.customerDetail.managerFullName = _.cloneDeep(oldManagerFullName);
                    this.customerDetail.concurrencyStamp = result.data as string;
                } else {
                    if (result.data) {
                        this.customerDetail.concurrencyStamp = result.data;
                    }
                    if (!this.customerDetail.managerId || this.customerDetail.managerId === '') {
                        this.customerDetail.managerId = this.currentUser.userId;
                        this.customerDetail.managerFullName = this.currentUser.fullName;
                    }
                }
            })
            .catch((e: any) => {
                this.$vs.notify({
                    title: '',
                    text: e.message,
                    color: 'danger'
                });
            });
    }
}
